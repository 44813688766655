.App,
body,
html,
canvas,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

body {
  /* background-color: #dedede; */
}
